import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-design-system"
    }}>{`General Design System`}</h2>
    <h3 {...{
      "id": "what-is-a-design-system"
    }}>{`What is a design system?`}</h3>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/get-started/introduction"
      }}>{`Getting Started`}</a>{` section to learn all about the Canvas
Design System.`}</p>
    <h3 {...{
      "id": "why-do-i-need-a-design-system"
    }}>{`Why do I need a design system?`}</h3>
    <p>{`Design systems eliminate the need for teams to start from scratch when designing a new product or
feature by providing them with the core building blocks that are reusable and quickly scalable for a
variety of product use cases.`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/get-started/introduction"
      }}>{`Getting Started`}</a>{` section to learn all about the Canvas
Design System.`}</p>
    <h3 {...{
      "id": "whats-available-to-me-in-canvas"
    }}>{`What’s available to me in Canvas?`}</h3>
    <p>{`This depends on the technology that your team is using to build your product. If your product/team
is able to use React components, then everything in Canvas is freely available to you:`}</p>
    <p>{`The contents of `}<a parentName="p" {...{
        "href": "https://workday.github.io/canvas-kit/"
      }}>{`Canvas Kit Web`}</a>{` are supported and ready to
use now. The `}<a parentName="p" {...{
        "href": "/styles/tokens/overview"
      }}>{`tokens`}</a>{`, `}<a parentName="p" {...{
        "href": "/styles/assets/overview"
      }}>{`assets`}</a>{` and
`}<a parentName="p" {...{
        "href": "/components/overview"
      }}>{`components`}</a>{` on this site will give you further information on each.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`For designers, the components in
`}<a parentName="p" {...{
          "href": "https://www.figma.com/file/RX6CqBf8VwO3NIyaMeFMf4/Canvas-Web-V9?node-id=19740-8418&t=CwJp2JDVNI9cgC2l-0"
        }}>{`Canvas Web Figma Library`}</a>{`
are ready to use now in your design files. They coincide 1:1 with what’s in Canvas Kit Web.`}</p>
      <p>{`If your product/team is only using metadata-driven UI, then you can only make use of what is
provided through the XO platform. This will be a mix of Canvas Kit Web components, and custom
components styled to look like Canvas.`}</p>
      <p>{`Check out the `}<a parentName="p" {...{
          "href": "https://confluence.workday.com/display/DEVCORN/Layouts"
        }}>{`XO Layouts Home Page`}</a>{` for an
overview, and visit
`}<a parentName="p" {...{
          "href": "https://wd5-pti.megaleo.com/superts01/d/task/2997$17992.htmld"
        }}>{`the Dev Hub task in Workday`}</a>{` to see
them in context.`}</p>
      <p>{`Designers can reference the Figma components in the
`}<a parentName="p" {...{
          "href": "https://www.figma.com/file/YuJ7rOSVpwygflXd1VeaQ1/Generated-UI?node-id=0-1&t=QHHguWVSsi5J7yuo-0"
        }}>{`Generated UI`}</a>{`
and `}<a parentName="p" {...{
          "href": "https://www.figma.com/file/dc8aeHk5JNAwRdltDu7oYB/WD-C-Library?node-id=0-1&t=udOC1dMVBj65IRTb-0"
        }}>{`WD
Components`}</a></p>
      <p>{`Note: the above resources contain most, but not all components that can be displayed with the
Standard Tooling platform.`}</p>
      <h4 {...{
        "id": "need-more-details-reach-out-to-the-canvas-team-on-slack"
      }}>{`Need more details? Reach out to the Canvas team on slack.`}</h4>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday-dev.slack.com/archives/C7AJWQ8BG"
          }}>{`#ask-canvas-design`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://workday-dev.slack.com/archives/C8X0W5AQH"
          }}>{`#ask-canvas-kit`}</a></li>
      </ul>
    </InternalContent>
    <h3 {...{
      "id": "what-is-the-difference-between-canvas-and-canvas-kit"
    }}>{`What is the difference between Canvas and Canvas Kit?`}</h3>
    <p>{`Canvas is the name of Workday’s design system. Within Canvas, there are various touch points or
sub-products that help designers and developers fully utilize Canvas.
`}<a parentName="p" {...{
        "href": "https://workday.github.io/canvas-kit/"
      }}>{`Canvas Kit Web`}</a>{`, one of those touch points, is the React
code library used by developers to implement tokens, assets, and components made available through
the Canvas Design System. Canvas Kit can be found on:`}</p>
    <ul>
      <li parentName="ul">{`NPM: the code package that contains libraries, necessary for developers to implement Canvas on
their products`}</li>
      <li parentName="ul">{`GitHub: contains the source code, including revision history and release notes`}</li>
    </ul>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "what-is-the-difference-between-canvas-and-our-brand-style-guide"
      }}>{`What is the difference between Canvas and our Brand Style Guide?`}</h3>
      <p>{`Canvas defines some foundations for both systems (color, space, etc.), but our
`}<a parentName="p" {...{
          "href": "https://brand.workday.com/"
        }}>{`Brand Style Guide (Workday Brand Central)`}</a>{` should be used as the
defacto source for any marketing or branding content. Canvas is designed in a way that is intended
to be agnostic of the Workday product, but it is most commonly used for product UI.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "as-a-designer-how-can-i-use-canvas"
    }}>{`As a designer, how can I use Canvas?`}</h3>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/get-started/for-designers/understanding-canvas"
      }}>{`Getting Started for Designers`}</a>{`
section of this site for guidance on understanding Canvas fundamentals and how to create custom
components and custom libraries.`}</p>
    <h3 {...{
      "id": "as-a-developer-how-can-i-use-canvas"
    }}>{`As a developer, how can I use Canvas?`}</h3>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "/get-started/for-developers/using-canvas"
      }}>{`Getting Started for Developers`}</a>{` section
of this site for guidance on understanding Canvas fundamentals and how to create custom components
and custom libraries.`}</p>
    <h2 {...{
      "id": "platforms"
    }}>{`Platforms`}</h2>
    <h3 {...{
      "id": "is-canvas-only-available-for-web-based-design"
    }}>{`Is Canvas only available for web based design?`}</h3>
    <InternalContent mdxType="InternalContent">
      <p>{`As of now, `}<a parentName="p" {...{
          "href": "https://workday.github.io/canvas-kit/"
        }}>{`Canvas Kit`}</a>{` is only available in CSS and React
for web. The mobile equivalent of Canvas Kit is currently being built and released. For mobile
component updates, please check the component overview table on the
`}<a parentName="p" {...{
          "href": "/components/overview"
        }}>{`Component Overview Page`}</a>{`. For unavailable mobile components, a
`}<a parentName="p" {...{
          "href": "https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile"
        }}>{`mobile design library`}</a>{` is
available in Figma for Canvas designers to use. For more information on Mobile, check out the
`}<a parentName="p" {...{
          "href": "https://sites.google.com/workday.com/mobile-ux-design"
        }}>{`Mobile UX Design Team`}</a>{` Google site to
understand more about the Native Mobile team.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`As of now, `}<a parentName="p" {...{
          "href": "https://workday.github.io/canvas-kit/"
        }}>{`Canvas Kit`}</a>{` is only available in CSS and React
for web. The mobile equivalent of Canvas Kit is not available.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "responsive-layouts-and-breakpoints"
    }}>{`Responsive layouts and breakpoints?`}</h3>
    <p>{`Canvas is currently working on guidance for responsive layouts, but this has not been finalised yet.
In the meantime please contact the Canvas team for more guidance.`}</p>
    <p>{`While there is no standardisation of breakpoints, we strongly recommend using the following
breakpoints to guide your designs. These are based on common device widths:`}</p>
    <ul>
      <li parentName="ul">{`Small (Mobile and small tablet): 320px - 767px`}</li>
      <li parentName="ul">{`Medium (Tablet): 768px - 1023px`}</li>
      <li parentName="ul">{`Large (Laptop and Small Desktop): 1024 - 1439px`}</li>
      <li parentName="ul">{`Extra Large (Desktop and Large Desktop): 1440+`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "how-can-i-learn-more-about-native-mobile-patterns-for-canvas"
      }}>{`How can I learn more about Native Mobile Patterns for Canvas?`}</h3>
      <p>{`As the guardians of consistency across the app, the
`}<a parentName="p" {...{
          "href": "https://sites.google.com/workday.com/mobile-ux-design/our-team/how-to-work-with-us?authuser=0"
        }}>{`Mobile UX Design Team`}</a>{`
need to keep tabs on all native mobile design work being done. If you are doing mobile design work,
their team will provide you with a Mobile Design Partner. They will help you understand mobile
design patterns and ensure your work integrates cohesively with the rest of the app.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "tokens"
    }}>{`Tokens`}</h2>
    <h3 {...{
      "id": "where-can-i-find-guidance-on-motion-and-animation"
    }}>{`Where can I find guidance on Motion and Animation?`}</h3>
    <p>{`Check out our article on `}<a parentName="p" {...{
        "href": "/styles/tokens/motion"
      }}>{`Motion`}</a>{` for guidance on motion and animation.`}</p>
    <h2 {...{
      "id": "assets"
    }}>{`Assets`}</h2>
    <h3 {...{
      "id": "how-can-i-request-a-new-icon-or-illustration"
    }}>{`How can I request a new icon or illustration?`}</h3>
    <p>{`Check out our section on contributing `}<a parentName="p" {...{
        "href": "/get-started/for-designers/contributing-to-canvas/"
      }}>{`tokens`}</a>{` for
more details about adding icons, illustrations and components to Canvas.`}</p>
    <h3 {...{
      "id": "where-can-i-find-empty-state-illustrations-in-canvas"
    }}>{`Where can I find Empty State Illustrations in Canvas?`}</h3>
    <InternalContent mdxType="InternalContent">
      <p>{`Check out our article on `}<a parentName="p" {...{
          "href": "/styles/assets/empty-states"
        }}>{`Empty States`}</a>{` for guidance within Canvas and the
`}<a parentName="p" {...{
          "href": "https://www.figma.com/file/SBz0nl5NXvA2pTYBDagXbB/Canvas-Assets?node-id=6932%3A401"
        }}>{`Canvas Assets library`}</a>{`
for the design artifacts.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Check out our article on `}<a parentName="p" {...{
          "href": "/styles/assets/empty-states"
        }}>{`Empty States`}</a>{` for guidance within Canvas.`}</p>
    </ExternalContent>
    <h2 {...{
      "id": "components"
    }}>{`Components`}</h2>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "are-prompts-available-in-canvas"
      }}>{`Are Prompts available in Canvas?`}</h3>
      <p>{`Not from Canvas Kit. Prompts are only available in
`}<a parentName="p" {...{
          "href": "https://d355bzs9u442xz.cloudfront.net/UIWEB-WDCOMPONENTS/LATEST/index.html?path=%2Fstory%2Fcomponents-multi-select-prompt--cities-without-header-multiselect"
        }}>{`WD-Components`}</a>{`
due to their complexity. Reach out to the #wd-components slack channel for more detailed enquiries.`}</p>
      <h3 {...{
        "id": "how-can-i-create-pex-cards"
      }}>{`How can I create Pex Cards?`}</h3>
      <p>{`Pex cards are owned and maintained by the Pex team, outside of Canvas. The Workday Today Card
Crafting `}<a parentName="p" {...{
          "href": "https://sites.google.com/workday.com/pexhomecardcrafting/home"
        }}>{`Google site`}</a>{` should help
guide you.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "what-makes-a-component-canvas"
    }}>{`What makes a component "Canvas"?`}</h3>
    <p>{`In an effort to help decide what should/shouldn't go into Canvas we've broken down some points
around what we think makes a component a prime candidate for the Design System.`}</p>
    <h5 {...{
      "id": "reusable"
    }}>{`Reusable`}</h5>
    <ul>
      <li parentName="ul">{`It can be used in a wide variety of contexts or products (beyond a single product or product area)`}</li>
      <li parentName="ul">{`It's in use already, or there is a present need for it in multiple contexts`}</li>
      <li parentName="ul">{`It's not similar to another existing component`}</li>
      <li parentName="ul">{`It uses Canvas tokens (spacing, color, typography)`}</li>
    </ul>
    <h5 {...{
      "id": "simple"
    }}>{`Simple`}</h5>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`It is a component, not a "composition" or "pattern"`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`A compositional component is a trivial compilation of primitive components into one, for a
specific use case (or simply to reduce technical complexity). If too many of these types of
components are contributed, they may dilute the core set of the components.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It does not have a lot of horizontal or nested complexity`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`The majority of our components have no more than ~4 levels of nesting. Too much more and it
becomes a "pattern". Within the levels of "component nesting", there should not be too much
horizontal complexity. Things that have too many elements can become very configurable. This
configurability often leads to a pattern more than a component.`}</p>
        </blockquote>
      </li>
    </ul>
    <h5 {...{
      "id": "agnostic"
    }}>{`Agnostic`}</h5>
    <ul>
      <li parentName="ul">{`It is core UI (e.g. a UI control), not based on a business process (e.g. a recruiting pipeline)`}</li>
      <li parentName="ul">{`It does not contain any business logic`}</li>
      <li parentName="ul">{`It could be used in any Cloud Platform integration or external product`}</li>
      <li parentName="ul">{`The same type of component could fit within other public design systems`}</li>
    </ul>
    <p>{`Many shared components at Workday are "reusable", but not necessarily "simple" or "agnostic".`}</p>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "whats-the-difference-between-wd-components-and-canvas-kit"
      }}>{`What's the difference between WD-Components and Canvas Kit?`}</h3>
      <p>{`Both `}<a parentName="p" {...{
          "href": "/help/glossary#wd-components"
        }}>{`WD-Components`}</a>{` (WDC) and
`}<a parentName="p" {...{
          "href": "/help/glossary#canvas-kit"
        }}>{`Canvas Kit Web`}</a>{` (CK) are shared component libraries
that aim to reduce duplication, improve consistency, and simplify UI development.`}</p>
      <p>{`WDC is generally a more composed library - think of Canvas Kit as lego bricks, and WDC as an
assembly of bricks. While both libraries are made of `}<em parentName="p">{`reusable`}</em>{` components, Canvas also requires
components to be `}<em parentName="p">{`simple`}</em>{` and `}<em parentName="p">{`agnostic`}</em>{`. `}<a parentName="p" {...{
          "href": "#what-makes-a-component-canvas"
        }}>{`Click here`}</a>{` to learn more
about the Canvas component guidelines.`}</p>
      <p>{`Both Canvas Kit Web and WDC components can be used by bespoke teams. Currently, XO metadata UI teams
can only make use of these projects if the component has been integrated into the Standard Tooling
platform.`}</p>
      <p>{`There are several differences between the two libraries outlined in the table below:`}</p>
      <table>
  <tr>
    <td>
      <span style={{
              "fontSize": "16px",
              "fontWeight": "bold"
            }}>Canvas Kit</span>
    </td>
    <td>
      <span style={{
              "fontSize": "16px",
              "fontWeight": "bold"
            }}>WD-Components</span>
    </td>
  </tr>
  <tr>
    <td style={{
            "borderBottom": "none",
            "paddingBottom": "0"
          }}>
      <strong>Technology:</strong>
      <ul>
        <li>Makes no assumptions</li>
        <li>
          Delivers a standalone design system experience (all components should be usable on their
          own, outside the Workday context)
        </li>
        <li>Supports multiple frameworks/platforms</li>
        <li>Open source/public for exposure and to allow customers to build with Workday Extend</li>
      </ul>
    </td>
    <td style={{
            "borderBottom": "0",
            "paddingBottom": "0"
          }}>
      <strong>Technology:</strong>
      <ul>
        <li>Makes some implicit assumptions based on the Workday Single Page Application (SPA)</li>
        <li>
          Delivers a design that’s in support of the UI platform, which may mean that it does not
          directly align with Canvas specifications.
        </li>
        <li>May contain business logic or platform dependencies</li>
        <li>Currently focused on React</li>
        <li>Inner source, no plans to open source</li>
      </ul>
    </td>
  </tr>
  <tr>
    <td>
      <strong>Components:</strong>
      <ul>
        <li>
          Basic building blocks that are modular and composable to create higher level interactions
        </li>
        <li>
          “Components”, not “compositions” or “patterns” (No more than ~4 levels of "component
          nesting")
        </li>
        <li>
          Core UI (e.g. a UI control), not based on a business process (e.g. a recruiting pipeline)
        </li>
        <li>Do not contain any business logic</li>
        <li>Could be used in any Workday Extend integration or external product</li>
        <li>Could fit within other public design systems</li>
      </ul>
    </td>
    <td>
      <strong>Components:</strong>
      <ul>
        <li>Uses Canvas Kit modular components where possible to form compositional components</li>
        <li>
          Can contain proprietary and/or opinionated interactions that we don’t want to release
          publicly (competitive advantage)
        </li>
        <li>
          Can contain legacy components we must support that we want to exclude from the design
          system/public offering
        </li>
        <li>
          Compositional components and patterns (groupings of components to reduce duplication that
          are not a fundamental part of the design system)
        </li>
      </ul>
    </td>
  </tr>
      </table>
      <p>{`You may occasionally find duplicates between the two projects. While our teams work to avoid this,
it may be required for two reasons:`}</p>
      <ul>
        <li parentName="ul">{`WDC wrapping a CK component to provide Workday-specific behavior.`}</li>
        <li parentName="ul">{`WDC providing an alternative to a CK component to provide Workday-specific behavior.`}</li>
      </ul>
      <p>{`If you come across a duplicate component you should generally start with the Canvas Kit option and
move to the WD-Components option if the CK equivalent does not support a Workday-specific use case.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "patterns"
    }}>{`Patterns`}</h2>
    <h3 {...{
      "id": "what-are-the-best-practices-for-using-truncation"
    }}>{`What are the best practices for using Truncation?`}</h3>
    <p>{`Each use case for truncation or overflow can be unique. Check out our article on the `}<LegacyPatternLink href="/patterns/overflow/" mdxType="LegacyPatternLink">{`overflow pattern`}</LegacyPatternLink>{` for more information.`}</p>
    <h2 {...{
      "id": "globalization-and-accessibility"
    }}>{`Globalization and Accessibility`}</h2>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "right-to-left-rtl-approaches-within-canvas"
      }}>{`Right to Left (RTL) approaches within Canvas?`}</h3>
      <p>{`Canvas Kit Web has RTL detection and will automatically flip the position of the icon in relation to
text, but will not automatically flip icons. Not all icons should be flipped and we have enough
context to know which, so that part is left up to the developer. We have ways of detecting RTL or
LTR so the correct icon could be provided. Reach out to Canvas on
`}<a parentName="p" {...{
          "href": "https://workday-dev.slack.com/archives/C8X0W5AQH"
        }}>{`#ask-canvas-kit`}</a>{` slack channel for detailed queries.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "right-to-left-rtl-approaches-within-canvas-1"
      }}>{`Right to Left (RTL) approaches within Canvas?`}</h3>
      <p>{`Canvas Kit Web has RTL detection and will automatically flip the position of the icon in relation to
text, but will not automatically flip icons. Not all icons should be flipped and we have enough
context to know which, so that part is left up to the developer. We have ways of detecting RTL or
LTR so the correct icon could be provided.`}</p>
    </ExternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      